import React, { useEffect } from "react";
import "./styles.css";
import { useForm } from "react-hook-form";
import Dialog from "rc-dialog";
import "rc-dialog/dist/rc-dialog.css";
import axios from "axios";
import { useWindowWidth } from "@react-hook/window-size";
import EasyForm from "./EasyForm";
import CardForm from "./CardForm";
import { getQueryVariable, validateEmail } from "./utils";

const MESSAGES = {
  voucherNotFound: "Voucher not found",
  expired: "Voucher expired",
  invalidParams: "Invalid params",
  running: "App is already running",
  pending:
    "App is starting. Please wait for email, it can take up to 15 minutes.",
  starting:
    "App is starting. Please wait for email, it can take up to 15 minutes.",
  error: "Unknown error",
};

const App = () => {
  const defaultCode = getQueryVariable("code") || "";
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: getQueryVariable("name") || "",
      email: getQueryVariable("email") || "",
      code: defaultCode,
    },
  });
  const windowsWidth = useWindowWidth();
  const showEasyFrom = windowsWidth < 840;
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [response, setResponse] = React.useState(null);

  const onSubmit = handleSubmit((values) => {
    const errors = [];
    if (!values.name) {
      errors.push("Please fill name");
    }
    if (!values.company_name) {
      errors.push("Please fill company name");
    }
    if (!values.email) {
      errors.push("Please fill email");
    } else if (!validateEmail(values.email)) {
      errors.push("Email is invalid");
    }
    if (!values.phone) {
      errors.push("Please fill phone");
    }
    if (!values.code) {
      errors.push("Please fill code");
    }
    if (errors.length > 0) {
      setFormErrors(errors);
      setShowErrorDialog(true);
    } else {
      setSubmitting(true);
      axios
        .get("https://api.pekatvision.com/vouchers/check", {
          params: {
            voucherId: values.code,
            email: values.email,
            company: values.company_name,
            phone: values.phone,
            website: "",
            name: values.name,
          },
        })
        .then((res) => {
          const { status } = res.data;
          if (status === "creating") {
            setSubmitted(true);
          } else {
            setShowErrorDialog(true);
            setFormErrors([MESSAGES[status]]);
          }
          setResponse(res.data);
        })
        .catch(() => {
          setFormErrors(["Unknown error"]);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <Dialog
        closeIcon={null}
        visible={!!showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        animation="zoom"
        maskAnimation="fade"
      >
        {formErrors &&
          formErrors.map((text, index) => <p key={index}>{text}</p>)}
        {response && response.status === "running" && (
          <p>
            Available at{" "}
            <a href={response.url} target={"_blank"}>
              {response.url}
            </a>
          </p>
        )}
        <button onClick={() => setShowErrorDialog(null)}>Close</button>
      </Dialog>
      {showEasyFrom ? (
        <EasyForm
          register={register}
          onSubmit={onSubmit}
          submitted={submitted}
          submitting={submitting}
        />
      ) : (
        <CardForm
          defaultCode={defaultCode}
          register={register}
          onSubmit={onSubmit}
          submitted={submitted}
          submitting={submitting}
        />
      )}
    </>
  );
};

export default App;
