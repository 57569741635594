import React from "react";
import FinalMessage from "./FinalMessage";

const EasyForm = ({ onSubmit, register, submitted, submitting }) => {
  return (
    <div className="easy-form">
      <img
        className="logo"
        src={"./pekat_vision_logo_claim_rgb_horizontal_inverse.svg"}
      />
      {!submitted ? (
        <form onSubmit={onSubmit}>
          <input
            disabled={submitting}
            required
            placeholder={"Name"}
            name="name"
            ref={register({})}
          />

          <input
            disabled={submitting}
            required
            placeholder={"Company name"}
            name="company_name"
            ref={register({})}
          />

          <input
            disabled={submitting}
            required
            placeholder={"E-mail"}
            name="email"
            type="email"
            ref={register({})}
          />

          <input
            disabled={submitting}
            required
            placeholder={"Phone number"}
            name="phone"
            ref={register({})}
          />
          <input
            disabled={submitting}
            required
            name="code"
            ref={register({})}
            placeholder={"Enter code"}
          />

          <button type="submit">Submit</button>
        </form>
      ) : (
        <p>
          <FinalMessage />
        </p>
      )}
    </div>
  );
};

export default EasyForm;
