import React from "react";
import axios from "axios";

const measureProcessingSpeed = async (url) => {
  const startTime = new Date().getTime();
  try {
    await axios.get(url);
    const endTime = new Date().getTime();
    const duration = endTime - startTime;
    return duration;
  } catch (error) {
    return Infinity;
  }
};

const FinalMessage = () => {
  const [tutorialLocation, setTutorialLocation] = React.useState();

  const measure = async () => {
    const dublin = await measureProcessingSpeed(
      "https://tutorial.pekatvision.com/16x16.png"
    );
    const tokio = await measureProcessingSpeed(
      "https://tutorial-asia.pekatvision.com/16x16.png"
    );
    if (dublin > tokio) {
      return "tokio";
    } else {
      return "dublin";
    }
  };

  React.useEffect(() => {
    measure().then((fasterLocation) => {
      setTutorialLocation(fasterLocation);
    });
  });

  return (
    <>
      Thank you for your interest in PEKAT VISION. The demo-tutorial is
      available{" "}
      <a
        href={`https://tutorial${
          tutorialLocation === "tokio" ? "-asia" : ""
        }.pekatvision.com`}
      >
        here
      </a>
      .
    </>
  );
};

export default FinalMessage;
