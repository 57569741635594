import React from "react";
import cn from "classnames";
import InputMask from "react-input-mask";
import FinalMessage from "./FinalMessage";

const Demo = ({ onSubmit, register, submitted, submitting, defaultCode }) => {
  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [closed, setClosed] = React.useState(false);
  const [flipped, setFlipped] = React.useState(false);
  const [scaled, setScaled] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (!hover) {
        setHover(true);
      }
    }, 2000);
    setTimeout(() => {
      setScaled(true);
    }, 500);
  }, []);
  React.useEffect(() => {
    setTimeout(() => {
      if (hover) {
        setOpen(true);
      }
    }, 500);
  }, [hover]);

  React.useEffect(() => {
    if (submitted) {
      setTimeout(() => {
        setHover(false);
        setTimeout(() => {
          setOpen(false);
        }, 580);
        setTimeout(() => {
          setClosed(true);
          setTimeout(() => {
            setFlipped(true);
          }, 500);
        }, 2000);
      }, 500);
    }
  }, [submitted]);

  return (
    <>
      <div
        className={cn(
          "book-wrap",
          hover && "start",
          closed && "closed",
          scaled && "scaled"
        )}
      >
        <form onSubmit={onSubmit}>
          {!flipped && (
            <div type="submit" className={cn("pull", hover && "down")}>
              <span className="submit-btn">Submit</span>
            </div>
          )}
          <div className="book-bg" onMouseEnter={() => false && setHover(true)}>
            <div className={cn("code-wrap code-wrap-1", hover && "down")}>
              <input
                disabled={submitting}
                required
                placeholder={"Name"}
                name="name"
                ref={register({})}
              />
            </div>

            <div className={cn("code-wrap code-wrap-2", hover && "down")}>
              <input
                disabled={submitting}
                required
                placeholder={"Company name"}
                name="company_name"
                ref={register({})}
              />
            </div>

            <div className={cn("code-wrap code-wrap-3", hover && "down")}>
              <input
                disabled={submitting}
                required
                placeholder={"E-mail"}
                name="email"
                type="email"
                ref={register({})}
              />
            </div>

            <div className={cn("code-wrap code-wrap-4", hover && "down")}>
              <input
                disabled={submitting}
                required
                placeholder={"Phone number"}
                name="phone"
                ref={register({})}
              />
            </div>

            <div className={cn("code-wrap", hover && "down")}>
              <InputMask
                inputRef={register({})}
                maskChar={"-"}
                className={"input-mask"}
                id={"input-mask"}
                //alwaysShowMask
                defaultValue={defaultCode}
                placeholder={"Enter code"}
                mask={"******"}
                required
                pattern="[A-Z0-9]{6}"
                title="Code"
                name="code"
                disabled={submitting}
                formatChars={{
                  "*": "[A-Z0-9]",
                }}
              />
            </div>

            <button type="submit" className="invisible-btn"></button>
            <div className={cn("book-cover", hover && "hover")}>
              {!open && (
                <img
                  style={{
                    width: 300,
                    marginLeft: 50,
                    marginTop: 100,
                  }}
                  src={"./pekat_vision_logo_claim_rgb_vertical_color.svg"}
                />
              )}
              {open && (
                <div className="left-page">
                  <p>Please fill in the form and click submit.</p>
                </div>
              )}
            </div>
            {flipped && (
              <div className="bg">
                <div className="back-page">
                  <h3>
                    <FinalMessage />
                  </h3>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Demo;
